<template>
  <div class="a-price" :class="className">
    <div :class="isHorizontal ? 'd-flex' : 'd-inline-block'">
      <div
        v-if="(!hideRegularPrice && !isProductOnDiscount) || (!hideRegularPrice && isProductOnDiscount && !isLavpris)"
        class="a-price__value"
        :class="{
          '-old': isProductOnDiscount,
          '-horizontal': isHorizontal
        }"
      >
        {{ includeVat ? price.regularFormatted : price.regularExVatFormatted }}
      </div>
      <div
        v-if="isProductOnDiscount"
        class="a-price__value -discount"
      >
        {{ includeVat ? price.priceFormatted : price.priceExVatFormatted }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Price',
  props: {
    price: {
      type: Object,
      required: true
    },
    includeVat: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    },
    isProductOnDiscount: {
      type: Boolean,
      default: false
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    hideRegularPrice: {
      type: Boolean,
      default: false
    },
    isLavpris: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentCulture () {
      return this.$store.getters['site/getCurrentCulture'];
    }
  }
};
</script>
